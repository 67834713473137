import React, { useEffect, useState } from 'react'
import { JobsWidgetType } from '@bbx/common/types/UserFeed'
import { useIsInView } from '@wh/common/chapter/hooks/useIsInView'
import { getJobsWidgetFromCookie } from '@bbx/search-journey/common/api/jobsApiClient'

const emptyJobsWidget = {
    ads: [],
    contextLinkList: [],
    title: '',
    listIndex: 0,
    type: 'JOBS_RECOMMENDATIONS',
    pulseMetadata: { listName: '' },
    additionalInfo: {
        isLookAlike: false,
        isJobSeeker: true,
        isDmpJobSeeker: true,
    },
} as JobsWidgetType

export const useLoadJobsWidget = (visibilityRef: React.RefObject<HTMLDivElement | HTMLAnchorElement>, isJobsWidgetHidden: boolean) => {
    const [jobsWidget, setJobsWidget] = useState<JobsWidgetType | undefined>(undefined)
    const [isTargetInView] = useIsInView(visibilityRef, '500px')

    useEffect(() => {
        const loadJobsWidget = async () => {
            try {
                const localJobsWidget = await getJobsWidgetFromCookie()

                if (localJobsWidget === undefined) {
                    setJobsWidget(emptyJobsWidget)
                    return
                }

                setJobsWidget(localJobsWidget)
            } catch (error) {
                setJobsWidget(emptyJobsWidget)
            }
        }

        if (isTargetInView && !isJobsWidgetHidden) {
            loadJobsWidget()
        }
    }, [isTargetInView, isJobsWidgetHidden])

    return jobsWidget
}
